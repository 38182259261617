import './style.scss';
import './src/js/libs/vendor/scroll-scope/scroll-scope.min.js';
import './src/js/libs/vendor/fancybox/jquery.fancybox.min.js';
import './src/js/libs/vendor/mmenu/jquery.mmenu.all.min';
import './src/js/libs/vendor/select2/select2.min.js';
import './src/components/live-ticker/live-ticker.js';
import 'vite/modulepreload-polyfill';
import 'virtual:svg-icons-register';

const components = [
  '.react',
  '.accordion',
  '[data-aos]',
  '.checklist',
  '.cookie-layer',
  '.countdown',
  '.countup',
  '.custom-datepicker',
  '.custom-select',
  '#doorpage',
  '.dropdown',
  '.flickity',
  '[data-fancybox]',
  '.form[data-validate="true"]',
  '.form',
  '.gmaps',
  '.grid-layout',
  '.header',
  '.nav-anchor',
  '.nav-main',
  '.nav-mobile',
  '.player-stage__stats',
  '.rellax',
  '.schedule-filter',
  '.scroll-top',
  '.social-bar',
  '.sponsored-by',
  '.stats-overview',
  '.tabs',
  '.timeline',
  '.widget-opta',
];

async function init() {
  console.log('[initalized] main.ts');
  // Dynamic importing of the component js
  for (let i = 0; i < components.length; i++) {
    switch (components[i]) {
      case '.accordion':
        if (document.querySelector(components[i])) {
          console.log('[detected] accordion component');
          const accordion = await import(
            './src/components/accordion/accordion'
          );
          accordion.default(document.querySelectorAll(components[i]));
        }
        break;
      case '[data-aos]':
        if (document.querySelector(components[i])) {
          console.log('[detected] aos component');
          const aos = await import('./src/components/aos/aos');
          aos.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.checklist':
        if (document.querySelector(components[i])) {
          console.log('[detected] checklist component');
          const checklist = await import(
            './src/components/checklist/checklist'
          );
          checklist.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.cookie-layer':
        if (document.querySelector(components[i])) {
          console.log('[detected] cookie-layer component');
          const cookieLayer = await import(
            './src/components/cookie-layer/cookie-layer'
          );
          cookieLayer.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.countdown':
        if (document.querySelector(components[i])) {
          console.log('[detected] countdown component');
          const countDown = await import(
            './src/components/countdown/countdown'
          );
          countDown.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.countup':
        if (document.querySelector(components[i])) {
          console.log('[detected] countup component');
          const countUp = await import('./src/components/countup/countup');
          countUp.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.custom-datepicker':
        if (document.querySelector(components[i])) {
          console.log('[detected] custom-datepicker component');
          const customDatepicker = await import(
            './src/components/custom-datepicker/custom-datepicker'
          );
          customDatepicker.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.custom-select':
        if (document.querySelector(components[i])) {
          console.log('[detected] custom-select component');
          const customSelect = await import(
            './src/components/custom-select/custom-select'
          );
          customSelect.default(document.querySelectorAll(components[i]));
        }
        break;
      case '#doorpage':
        if (document.querySelector(components[i])) {
          console.log('[detected] doorpage component');
          const doorPage = await import('./src/components/doorpage/doorpage');
          doorPage.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.dropdown':
        if (document.querySelector(components[i])) {
          console.log('[detected] dropdown component');
          const dropdown = await import('./src/components/dropdown/dropdown');
          dropdown.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.flickity':
        if (document.querySelector(components[i])) {
          console.log('[detected] flickity component');
          const flickity = await import('./src/components/flickity/flickity');
          flickity.default(document.querySelectorAll(components[i]));
        }
        break;
        case '[data-fancybox]':
        if (document.querySelector(components[i])) {
          console.log('[detected] fancybox component');
          const fancybox = await import('./src/components/fancybox/fancybox');
          fancybox.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.form[data-validate="true"]':
        if (document.querySelector(components[i])) {
          console.log('[detected] .form[data-validate="true"] component');
          const form = await import('./src/components/form/form-validation');
          form.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.form':
        if (document.querySelector(components[i])) {
          console.log('[detected] form component');
          const form = await import('./src/components/form/form');
          form.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.gmaps':
        if (document.querySelector(components[i])) {
          console.log('[detected] gmaps component');
          const gmaps = await import('./src/components/gmaps/gmaps');
          gmaps.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.grid-layout':
        if (document.querySelector(components[i])) {
          console.log('[detected] grid-layout component');
          const gridLayout = await import(
            './src/components/grid-layout/grid-layout'
          );
          gridLayout.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.header':
        if (document.querySelector(components[i])) {
          console.log('[detected] header component');
          const header = await import('./src/components/header/header');
          header.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.nav-anchor':
        if (document.querySelector(components[i])) {
          console.log('[detected] nav-anchor component');
          const navAnchor = await import(
            './src/components/nav-anchor/nav-anchor'
          );
          navAnchor.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.player-stage__stats':
        if (document.querySelector(components[i])) {
          console.log('[detected] player-stage component');
          const playerStageStats = await import(
            './src/components/player-stage/player-stage'
          );
          playerStageStats.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.nav-main':
        if (document.querySelector(components[i])) {
          console.log('[detected] nav-main component');
          const navMain = await import('./src/components/nav-main/nav-main');
          navMain.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.nav-mobile':
        if (document.querySelector(components[i])) {
          console.log('[detected] nav-mobile component');
          const navMobile = await import(
            './src/components/nav-mobile/nav-mobile'
          );
          navMobile.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.rellax':
        if (document.querySelector(components[i])) {
          console.log('[detected] rellax component');
          const rellax = await import('./src/components/rellax/rellax');
          rellax.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.schedule-filter':
        if (document.querySelector(components[i])) {
          console.log('[detected] schedule-filter component');
          const scheduleFilter = await import(
            './src/components/schedule-filter/schedule-filter'
          );
          scheduleFilter.default();
        }
        break;
      case '.scroll-top':
        if (document.querySelector(components[i])) {
          console.log('[detected] scroll-top component');
          const scrollTop = await import(
            './src/components/scroll-top/scroll-top'
          );
          scrollTop.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.social-bar':
        if (document.querySelector(components[i])) {
          console.log('[detected] social-bar component');
          const socialBar = await import(
            './src/components/social-bar/social-bar'
          );
          socialBar.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.sponsored-by':
        if (document.querySelector(components[i])) {
          console.log('[detected] sponsored-by component');
          const sponsoredBy = await import(
            './src/components/sponsored-by/sponsored-by'
          );
          sponsoredBy.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.stats-overview':
        if (document.querySelector(components[i])) {
          console.log('[detected] stats-overview component');
          const statsOverview = await import(
            './src/components/stats-overview/stats-overview'
          );
          statsOverview.default();
        }
        break;
      case '.tabs':
        if (document.querySelector(components[i])) {
          console.log('[detected] tabs component');
          const tabs = await import('./src/components/tabs/tabs');
          tabs.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.timeline':
        if (document.querySelector(components[i])) {
          console.log('[detected] timeline component');
          const timeline = await import('./src/components/timeline/timeline');
          timeline.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.widget-opta':
        if (document.querySelector(components[i])) {
          console.log('[detected] widget-opta component');
          const widgetOpta = await import(
            './src/components/widget-opta/widget-opta'
          );
          widgetOpta.default(document.querySelectorAll(components[i]));
        }
        break;
      default:
        break;
    }
  }

  // If the the page is served on gitlab pages, expand all links with the base path
  // variable
  if (document.location.hostname.includes('pages-brandung.de')) {
    import('./src/partials/base-path-helper').then((module) =>
      module.setBasePathInTemplates(),
    );
  }
  // }
  // const module = await import('./src/js/function/get-viewport.js');
  // const module2 = await import('./src/js/libs/vendor/countUp/countUp.min.js')
}

init();
