/**
 * LiveTicker
 *
 * Small widget for the stage of albaberlin.de to display score and time played for
 * the bundesliga and the euroleague games.
 *
 * There is some rudimentary documentation for the "api" of the Bundesliga and EuroLeague:
 *
 * BBL: https://wiki.brandung.de/display/ALBABR/BBL+Datafeeds
 * EL: https://wiki.brandung.de/display/ALBABR/Euroleague+Feeds
 *
 * The BBL API delivers "JSON" via *.JSN files (hence the helper function `textToJSON`)
 * The EL API delivers regular XML.
 */

class LiveTicker {
  el = null;
  gameType = null;
  countdown = null;
  selectors = {
    toggle: '.js-lt-toggle',
    headline: '.js-lt-headline',
    gametime: '.js-lt-gametime',
    gameday: '.js-lt-gameday',
    messages: '.js-lt-message',
    league: '.js-lt-league',
    contentWrapper: '.js-lt-content-wrapper',
    scoreHome: '.js-lt-score-home',
    scoreAway: '.js-lt-score-away'
  };
  env =
    window.location.hostname === 'localhost' ||
      window.location.hostname.indexOf('192.168') !== -1 || window.location.hostname.indexOf('www-ekruehne-alba-development') !== -1
      ? 'dev'
      : 'prod';
  settings = {
    api: {
      endpoints: {
        bbl: '',
        el: ''
      }
    }
  };
  countdownInterval = null;
  elements = {}; // generated from selectors

  constructor(element) {
    if (element) {
      this.el = element;

      this.generateApiEndpoints();
      this.buildElementReferences();
      this.initTickerState();
      this.bindEvents();
    }
  }

  generateApiEndpoints() {
    // bundesliga
    this.settings.api.endpoints.bbl = this.processBblEndpoint(
      this.el.dataset.liveurl
    );

    // euroleague
    this.settings.api.endpoints.el = this.el.dataset.liveurl;
  }

  bindEvents() {
    // start fetching data from the api once the countdown is over
    this.el.addEventListener('ticker:countdownOver', () => {
      this.switchToTickerLayout();
      this.fetchApiData();
    });

    this.elements.toggle.addEventListener('click', evt => {
      evt.stopPropagation();

      this.toggleVisibility();
    });
  }

  initTickerState() {
    const dataSet = this.el.dataset;

    if (dataSet) {
      if (dataSet.gameType) {
        this.gameType = this.el.dataset.gameType.toLowerCase();
      } else {
        throw new Error('Game type is required to init the ticker');
      }

      if (dataSet.countdown && dataSet.countdown !== '') {
        const utcUnixTimeStamp = Number(dataSet.countdown);
        this.countdown = this.utcToLocalTime(utcUnixTimeStamp);

        this.initCountdown();
      } else {
        this.fetchApiData();
      }
    } else {
      throw new Error('Missing data attributes to init the ticker');
    }
  }

  initCountdown() {
    // delete score board
    this.elements.contentWrapper.innerHTML = '';

    // set proper headline for the ticker
    this.elements.headline.innerHTML = 'Countdown';

    // set the played time to zero
    this.elements.gametime.innerHTML = "00:00'";

    // append countdown template
    this.elements.contentWrapper.innerHTML = this.generateCountdownTemplate();

    // fadein all ticker components
    this.tickerInitializationFinished();

    this.countdownInterval = setInterval(() => {
      const countdownMarkup = this.generateCountdownTemplate();

      if (countdownMarkup) {
        this.elements.contentWrapper.innerHTML = countdownMarkup;
      }
    }, 1000);
  }

  toggleVisibility() {
    if (this.el.classList.contains('is-visible')) {
      this.el.classList.remove('is-visible');
    } else {
      this.el.classList.add('is-visible');
    }
  }

  switchToTickerLayout() {
    // set proper headline for the ticker
    this.elements.headline.innerHTML = 'Live';

    // delete countdown
    this.elements.contentWrapper.innerHTML = `<div class="live-ticker__score">
			<div class="live-ticker__score-home js-lt-score-home">
				00
			</div>
			<div class="live-ticker__score-divider">
				:
			</div>
			<div class="live-ticker__score-away js-lt-score-away">
				00
			</div>
		</div>`;

    // rebuild dom refereces
    this.buildElementReferences();
  }

  generateCountdownTemplate() {
    const units = this.calculateCountdownValues(
      this.countdown - Math.round(Date.now() / 1000)
    );

    if (units.hours <= 0 && units.min <= 0 && units.sec <= 0) {
      const evt = new CustomEvent('ticker:countdownOver');

      this.el.dispatchEvent(evt);

      clearInterval(this.countdownInterval);

      return false;
    }

    return `<div class="live-ticker__countdown">
			<div class="">${units.hours.toString().length === 1 ? `0${units.hours}` : units.hours
      }</div>:
			<div class="">${units.min.toString().length === 1 ? `0${units.min}` : units.min
      }</div>:
			<div class="">${units.sec.toString().length === 1 ? `0${units.sec}` : units.sec
      }</div>
		</div>`;
  }

  calculateCountdownValues(delta) {
    let units = { hours: null, min: null, sec: null },
      hoursRest,
      minRest;

    units.hours = Math.floor(delta / 60 / 60);
    hoursRest = delta % (60 * 60);

    units.min = Math.floor(hoursRest / 60);
    minRest = hoursRest % 60;

    units.sec = minRest;

    if (units.hours < 0) {
      units.hours = 0;
    }
    if (units.min < 0) {
      units.min = 0;
    }
    if (units.sec < 0) {
      units.sec = 0;
    }

    return units;
  }

  tickerInitializationFinished() {
    this.el.classList.add('is-init');
  }

  renderData(data) {
    // set score values
    this.elements.scoreHome.textContent =
      data.score.home.toString().length === 1
        ? `0${data.score.home}`
        : data.score.home;
    this.elements.scoreAway.textContent =
      data.score.away.toString().length === 1
        ? `0${data.score.away}`
        : data.score.away;

    if (data.gameEnded) {
      this.elements.headline.textContent = 'Post Game';
      this.elements.gametime.textContent = 'Ende';
    } else {
      // set played time
      this.elements.gametime.textContent = `${data.timePlayed.min.toString().length === 1
        ? `0${data.timePlayed.min}`
        : data.timePlayed.min
        }:${data.timePlayed.sec.toString().length === 1
          ? `0${data.timePlayed.sec}`
          : data.timePlayed.sec
        }'`;
    }

    this.tickerInitializationFinished();
  }

  fetchApiData() {
    const self = this;

    $.ajax({
      url: this.returnAPIEndpoint(),
      method: 'GET',
      dataType: this.gameType === 'el' ? 'xml' : 'text',
      success: data => {
        try {
          const processedData =
            this.gameType === 'el'
              ? this.xmlToJson(data)
              : this.textToJSON(data);

          this.renderData(this.normalizeData(processedData));
        } catch (e) {
          this.tickerInitializationFinished();
          this.displayMessage(
            'error',
            'Bei der Verarbeitung der Daten ist ein Fehler aufgetreten'
          );
        }
      },
      error: () => {
        this.tickerInitializationFinished();

        if (this.gameType === 'el') {
          this.displayMessage(
            'error',
            'Die Daten der EuroLeague können zur Zeit nicht abgerufen werden'
          );
        } else if (this.gameType === 'bbl') {
          this.displayMessage(
            'error',
            'Die Daten der Basketball Bundesliga können zur Zeit nicht abgerufen werden'
          );
        }
      },
      complete: () => {
        setTimeout(function () {
          self.fetchApiData();
        }, 20000);
      }
    });
  }

  normalizeData(data) {
    const structeredData = {
      score: {
        home: 0,
        away: 0
      },
      timePlayed: {
        min: 0,
        sec: 0
      },
      gameEnded: false
    };

    if (data !== null) {
      if (this.gameType === 'bbl') {
        const lastScore = data.scorelist[data.scorelist.length - 1];
        const timePlayed = this.calculateTimePlayed(data);

        if (lastScore) {
          structeredData.score.home = lastScore[3];
          structeredData.score.away = lastScore[4];
        } else {
          // no last score available: assume it is pre game or no points were scored yet
          structeredData.score.home = 0;
          structeredData.score.away = 0;
        }

        structeredData.timePlayed.min = timePlayed.min;
        structeredData.timePlayed.sec = timePlayed.sec;

        if (data.ActPer.toLowerCase() === 'e') {
          structeredData.gameEnded = true;
        }
      } else if (this.gameType === 'el') {
        let currentGame = null;

        if (data.games.game.length > 1) {
          for (const prop in data.games.game) {
            if (
              data.games.game[prop].gamecode['#text'] ===
              `euro_${this.el.dataset.gameId.split('_')[1]}`
            ) {
              currentGame = data.games.game[prop];
            }
          }
        } else {
          if (
            data.games.game.gamecode['#text'] ===
            `euro_${this.el.dataset.gameId.split('_')[1]}`
          ) {
            currentGame = data.games.game;
          }
        }

        if (currentGame) {
          structeredData.score.home = currentGame.homepts['#text'];
          structeredData.score.away = currentGame.awaypts['#text'];

          structeredData.timePlayed.min = currentGame.minute['#text'].split(
            ':'
          )[0];
          structeredData.timePlayed.sec = currentGame.minute['#text'].split(
            ':'
          )[1];

          if (
            currentGame.status['#text'].toLowerCase() === 'off' &&
            currentGame.minute['#text'].toLowerCase() !== '00:00'
          ) {
            structeredData.gameEnded = true;
          }
        }
      }

      return structeredData;
    } else {
      return false;
    }
  }

  calculateTimePlayed(data) {
    const timePlayed = {
      min: null,
      sec: null
    };
    const dataRef =
      this.gameType === 'bbl'
        ? data.scorelist[data.scorelist.length - 1]
        : null;

    if (dataRef && this.gameType === 'bbl') {
      const currentQuarter = dataRef[1];
      const remainingTs = dataRef[2];
      const remainingMin = parseInt(remainingTs.split(':')[0]);
      const remainingSec = parseInt(remainingTs.split(':')[1]);
      const sumSecondsLeftInQuater =
        parseInt(remainingMin) * 60 + parseInt(remainingSec);

      let timeBase = 0;

      switch (currentQuarter) {
        case 'Q1':
          timeBase = 600;
          break;
        case 'Q2':
          timeBase = 1200;
          break;
        case 'Q3':
          timeBase = 1800;
          break;
        case 'Q4':
          timeBase = 2400;
          break;
      }

      timePlayed.min = Math.floor((timeBase - sumSecondsLeftInQuater) / 60);
      timePlayed.sec = (timeBase - sumSecondsLeftInQuater) % 60;

      if (data.ActPer.toLowerCase() === 'e') {
        timePlayed.min = 40;
        timePlayed.sec = 0;
      }
    } else {
      timePlayed.min = 0;
      timePlayed.sec = 0;
    }

    return timePlayed;
  }

  displayMessage(type, message) {
    let messageClass = '';

    if (type === 'error') {
      messageClass = 'error';
    } else {
      messageClass = 'success';
    }

    if (this.elements.messages) {
      this.elements.messages.classList.add(messageClass);
      this.elements.messages.textContent = message;
      this.elements.messages.style.display = 'block';

      setTimeout(() => {
        this.elements.messages.style.display = 'none';
        this.elements.messages.classList.remove(messageClass);
        this.elements.messages.textContent = '';
      }, 5000);
    }
  }

  /**
   * Helper functions
   */
  buildElementReferences() {
    for (const element in this.selectors) {
      this.elements[element] = this.el.querySelector(this.selectors[element]);
    }
  }
  returnAPIEndpoint() {
    let result;
    switch (this.env) {
      case 'dev':
        switch (this.gameType) {
          case 'bbl':
            result = '/typo3conf/ext/bra_projectfiles/Resources/Private/Frontend/components/live-ticker/bbl-dummy.JSN';
            break;
          case 'el':
            result = '/typo3conf/ext/bra_projectfiles/Resources/Public/Frontend/el-mockdata/el-gamesxml-dummy.xml';
            break;
        }
        break;
      case 'prod':
        switch (this.gameType) {
          case 'bbl':
            result = this.settings.api.endpoints.bbl;
            break;
          case 'el':
            result = this.settings.api.endpoints.el;
            break;
        }
        break;
    }
    return result;
  }
  xmlToJson(xml) {
    var obj = {};

    if (xml.nodeType == 1) {
      // element
      // do attributes
      if (xml.attributes.length > 0) {
        obj['@attributes'] = {};
        for (var j = 0; j < xml.attributes.length; j++) {
          var attribute = xml.attributes.item(j);
          obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
        }
      }
    } else if (xml.nodeType == 3) {
      // text
      obj = xml.nodeValue;
    }

    // do children
    if (xml.hasChildNodes()) {
      for (var i = 0; i < xml.childNodes.length; i++) {
        var item = xml.childNodes.item(i);
        var nodeName = item.nodeName;
        if (typeof obj[nodeName] == 'undefined') {
          obj[nodeName] = this.xmlToJson(item);
        } else {
          if (typeof obj[nodeName].push == 'undefined') {
            var old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(this.xmlToJson(item));
        }
      }
    }

    return obj;
  }
  textToJSON(text) {
    return JSON.parse(text);
  }
  utcToLocalTime(utcUnixTs) {
    const utcUnixTsInMs = utcUnixTs * 1000;
    const utcDate = new Date(utcUnixTsInMs);
    const utcOffsetToLocal = utcDate.getTimezoneOffset();

    // convert utc unix timestamp to minutes and substract timezone difference
    // and return local unix timestamp
    return (utcUnixTsInMs / 1000 / 60 + utcOffsetToLocal) * 60;
  }
  processBblEndpoint(url) {
    // replace http with https
    let processedUrl =
      url.indexOf('https') === -1 ? url.replace('http', 'https') : url;

    // in case old bbl url is being passed replace with new one
    return processedUrl.replace('beko-bbl', 'easycredit-bbl');
  }
}

(function () {
  const el = document.querySelector('.live-ticker');

  if (el) {
    return new LiveTicker(el);
  }
})();
